<template>
    <section :id="cmsBlock.anchorTag" class="component-container" :class="cmsBlock.graphicColumnStyle">
        <div class="graphic-columns-wrapper">
            <h3 class="header">{{ localize(this.cmsBlock.header) }}</h3>
            <div class="graphic-columns">
                <div class="graphic-column" v-for="(col, index) in this.cmsBlock.graphicColumns" :key="col.id">
                    <picture>
                        <template v-for="[format, set] of Object.entries(srcsets[index])">
                            <source v-if="format !== originalFormats[index]" :srcset="set"
                                sizes="(max-width: 1100px) 75vw, 30vw" :type="'image/' + format.slice(1)">
                        </template>
                        <img :srcset="srcsets[index][originalFormats[index]]" class="column-image"
                            :type="'image/' + originalFormats[index].slice(1)" sizes="(max-width: 1100px) 75vw, 30vw"
                            :alt="col.image.data.attributes.alternativeText">
                    </picture>
                    <h5 class="column-title">
                        {{ localizeAlt(col, 'title') }}
                    </h5>
                    <p class="column-text" v-html="md(localizeAlt(col, 'description'))"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'GraphicColumnsBlock',

    props: [
        'cmsBlock'
    ],

    computed: {
        colWidth() {
            return 100 / this.cmsBlock.graphicColumns.length + '%'
        },

        srcsets() {
            let srcsets = []
            for (const col of this.cmsBlock.graphicColumns) {
                srcsets.push(this.getImageSrcsets(col.image.data.attributes))
            }
            return srcsets
        },

        hasWebp() {
            let hasWebps = []
            for (const srcset of this.srcsets) {
                hasWebps.push(srcset['.webp'] ? true : false)
            }
            return hasWebps
        },

        originalFormats() {
            let originalFormats = []
            for (const srcset of this.srcsets) {
                for (const [format, _] of Object.entries(srcset)) {
                    if (format !== '.webp') {
                        originalFormats.push(format)
                    }
                }
            }

            return originalFormats
        },

        /* gridCells() { */
        /*     var gridColStyle = '' */
        /*     this.cmsBlock.graphicColumns.forEach(function(col){ */
        /*         gridColStyle += '1fr ' */
        /*     }) */
        /*     return { */
        /*         gridTemplateColumns: gridColStyle */
        /*     } */
        /* } */
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    .graphic-columns-wrapper {
        max-width: $container-width;
        margin: 0 auto;

        .header {
            margin-top: 0;
            margin-bottom: 40px;

            @include breakpoint('tablet') {
                font-size: getFontSize('textHeader', 'tablet');
            }

            @include breakpoint('mobile') {
                font-size: getFontSize('textHeader', 'mobile');
            }
        }

        .graphic-columns {
            padding-bottom: 1px;
            overflow: hidden;
            display: grid;
            width: 100%;
            grid-template-columns: unset;
            grid-auto-columns: minmax(0, 1fr); 
            grid-auto-flow: column;
            
            @include breakpoint('tablet') {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-auto-columns: unset; 
                grid-auto-flow: unset;  
            }

            @include breakpoint('mobile') {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-auto-columns: unset; 
                grid-auto-flow: unset; 
            }

            /* @include breakpoint('max-width') { */
            /*     margin: 0 calc(#{getSpacing('section-left-right', 'desktop')} - 20px); */
            /* } */
            /* @include breakpoint('tablet') { */
            /*     margin: 0 calc(#{getSpacing('section-left-right', 'tablet')} - 20px); */
            /* } */
            /* @include breakpoint('mobile') { */
            /*     /1* grid-template-columns: 1fr !important; *1/ */
            /*     margin: 0 calc(#{getSpacing('section-left-right', 'mobile')} - 20px); */
            /* } */

            .graphic-column {
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: center;
                padding: getSpacing('padding-default-horizontal', 'desktop');

                @include breakpoint('tablet') {
                    //width: calc(50% -  2* getSpacing('padding-default-horizontal', 'desktop'));
                }

                @include breakpoint('mobile') {
                    //width: calc(100% - getSpacing('padding-default-horizontal', 'desktop'));
                }

                .column-image {
                    width: 100%;
                    height: 250px;
                    margin-bottom: 20px;
                    object-fit: contain;
                    object-position: center;

                    @include breakpoint('tablet') {
                        width: 100%;
                    }

                    @include breakpoint('mobile') {
                        width: 100%;
                    }
                }

                .column-title {
                    margin-bottom: 0;
                    font-size: getFontSize('defaultText', 'desktop');
                    font-family: getFontFamily('defaultText');
                    font-weight: getFontWeight('defaultText');
                    font-style: getFontStyle('defaultText');

                    @include breakpoint('tablet') {
                        font-size: getFontSize('defaultText', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('defaultText', 'mobile');
                    }
                }

                .column-text {
                    font-size: getFontSize('smallText', 'desktop');
                    font-weight: getFontWeight('smallText');
                    font-style: getFontStyle('smallText');
                    text-align: start;
                    margin-bottom: 0;

                    @include breakpoint('tablet') {
                        font-size: getFontSize('smallText', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('smallText', 'mobile');
                    }
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.graphic-columns {
    .graphic-column {
        .column-text {
            p {
                font-size: inherit;
                font-weight: inherit;
                font-style: inherit;
                text-align: inherit;
            }
        }
    }
}
</style>
